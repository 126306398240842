/* All tables */
.cb-table {
  margin-bottom: 2rem;
  width: 100%;  
}

.cb-table .cell-active {
  color: #9C2729;
}

.cb-table th:after {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: relative;
  transform: rotate(135deg);
  width: 6px;
  margin-left: 5px;
  top: -2px;
}

.cb-table th.bottom:after {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: relative;
  transform: rotate(-45deg);
  top: 0;
}

.cb-table th {
  position: relative;
  padding: 10px 5px;
  padding-right: 1rem;
}

.cb-table tbody tr {
  cursor: pointer;
  border-left: 5px solid transparent;
}

.cb-table-nocursor tbody tr {
  cursor: default;
}

.cb-table-alternative tbody tr:nth-child(even) {
  background-color: #EEF0F3;
}

.cb-table-hover tbody tr:hover,
.cb-table-hover tbody tr:nth-child(even):hover {
  background-color: rgba(156, 39, 41, 0.1);
  border-left: 5px solid rgba(156, 39, 41, 1);
}

.cb-table td {
  font-size: 14px;
  max-width: 10rem;
  min-height: 76px;
  padding: 15px 5px;
}

.cell-active {
  color: #9C2729;
}

@media only screen and (max-width: 990px) {
  .cb-table table {
    min-height: none; 
  }

  table {
    margin: 0;
    width: 100%;
  }

	/* Force table to not be like tables anymore */
	.cb-table table, 
	.cb-table thead, 
	.cb-table tbody, 
	.cb-table th, 
	.cb-table td, 
	.cb-table tr { 
		display: block; 
  }

  .cb-table tr {
    border-radius: 4px;
    border: solid 1px #f6f9fc;
    background-color: #ffffff;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
  }

  .cb-table tr:nth-child(even) {
    background-color: #FFF;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
	.cb-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  
	.cb-table td { 
		/* Behave  like a "row" */
    border: none;
    max-width: 100%;
    min-height: 0;
    position: relative;
    padding: 10px;
		padding-left: 50%;
		white-space: normal;
		text-align:left;
  }
  
  .cb-table td.cb-pdf-file {
    padding-left: 10px;
  }
 
	.cb-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
    width: 45%; 
    opacity: 0.5;
    padding: 0 20px; 
		top: 10px;
		white-space: nowrap;
		text-align:left;
	}
 
	/*
	Label the data
	*/
  .cb-table td:before { content: attr(data-title); }
  
  .cb-table .cell-pdf-icon img {
    display: none;
  }
  .cb-table .cell-pdf-icon span {
    display: block;
    margin-left: 0;
  }
  
}

/* PDF Icon */
.cb-table .cell-pdf-icon span {
  display: block;
  margin-left: 2.5rem;
}

.cb-table .cell-pdf-icon img {
  margin-right: .5rem;
  position: absolute;
  top: 10px;
}

#convex-bond-matrix th {
  font-size: 12px;
  padding: 10px 10px 10px 5px;
  position: relative;
}

/* Latest updates tables */
.section-latest-transactions,
.section-latest-notices {
  padding-bottom: 2rem;
  width: 100%;
}

.cb-table-latest {
  margin-top: 1.5rem;
}

.cb-table-latest .cell-pdf-icon img {
  top: 16px;
}

.button-all.button-first {
  display: none;
}

.section-latest-transactions h2,
.section-latest-notices h2 {
  border-top: 1px solid hsla(0, 0%, 0%, 0.12);
  padding-top: 1rem;
}

.cb-button-pdf {
  min-width: 100%;
}

@media screen and (min-width: 990px) {
  .section-latest-transactions,
  .section-latest-notices {
    float: left;
    padding: 1rem;
    padding-bottom: 0;
  }
  .section-latest-transactions h2,
  .section-latest-notices h2 {
    border-top: none;
    padding-top: 0;
  }
  .section-latest-notices {
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);
  }
  .button-all {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }
  .button-all.button-first {
    float: right;
  }
  .cb-button-pdf {
    float: right;
    min-width: 100%;
  }
  .section-latest-transactions {
    padding: 2rem 5% 2rem 0;
    width: 60%;
  }
  .section-latest-notices {
    padding: 2rem 1rem;
    width: 40%;
  }
  .cb-table-latest td {
    height: 60px;
    padding: 2px 5px;
    position: relative;
  }
  .cb-table-narrow td {
    height: 40px;
    padding: 7px 5px;
  }
}