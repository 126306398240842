.react-tabs__tab {
  width: auto;
  display: inline-block;
  font-size: 16px;
}

ul.react-tabs__tab-list {
  width: 100%;
  display: block;
  white-space: nowrap;
  /* font-size: 0; */
  overflow: auto;
}

.react-tabs__tab--selected {
  background: #fff;
  color: black;
  border-radius: 0;
  border: none;
}