@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap);
html {
  font-family: 'Open Sans', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
  font-weight: bolder;
}
dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: 700;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
html {
  font: 16px/1rem georgia, serif;
  box-sizing: border-box;
  overflow-y: scroll;
}
* {
  box-sizing: inherit;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
body {
  color: hsla(0, 0%, 0%, 0.8);
  font-family: 'Open Sans', serif;
  font-weight: normal;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
          font-kerning: normal;
  -ms-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
}
img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
h1 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-size: 1.75rem;
  line-height: 1.1;
}
h1.centre {
  text-align: left;
}
h2 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: .5rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-size: 1.5rem;
  line-height: 1.1;
}
h3 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1.38316rem;
  line-height: 1.1;
}
h4 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  line-height: 1.1;
}
h5 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.85028rem;
  line-height: 1.1;
}
h6 {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  font-size: 0.78405rem;
  line-height: 1.1;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 2em;
  }
  h1.centre {
    text-align: center;
  }
  h2 {
    font-size: 1.75em;
  }
}
hgroup {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
ul {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}
ol {
  margin-left: 1.45rem;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  list-style-position: outside;
  list-style-image: none;
}
dl {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
dd {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
p {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
p.center {
  text-align: center;
}
figure {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
pre {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 1.45rem;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
  padding: 1.45rem;
}
table {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
  margin: auto;
  font-size: 1rem;
  line-height: 1.45rem;
  border-collapse: collapse;
}
fieldset {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
blockquote {
  margin-left: 1.45rem;
  margin-right: 1.45rem;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
form {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
noscript {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
iframe {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
hr {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}
address {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
}
b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}
dt {
  font-weight: bold;
}
th {
  font-weight: normal;
  font-size: 14px;
  color: #878787;
}
td { 
  color: #333333;
  font-size: 12px;
  position: relative;
}
li {
  margin-bottom: calc(1.45rem / 2);
}
ol li {
  padding-left: 0;
}
ul li {
  padding-left: 0;
}
li > ol {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
li > ul {
  margin-left: 1.45rem;
  margin-bottom: calc(1.45rem / 2);
  margin-top: calc(1.45rem / 2);
}
blockquote *:last-child {
  margin-bottom: 0;
}
li *:last-child {
  margin-bottom: 0;
}
p *:last-child {
  margin-bottom: 0;
}
li > p {
  margin-bottom: calc(1.45rem / 2);
}
code {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
kbd {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
samp {
  font-size: 0.85rem;
  line-height: 1.45rem;
}
abbr {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
acronym {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
}
abbr[title] {
  border-bottom: 1px dotted hsla(0, 0%, 0%, 0.5);
  cursor: help;
  text-decoration: none;
}
thead {
  border-bottom: 1px solid hsla(0, 0%, 0%, 0.12);
  text-align: left;
}
td,
th {
  text-align: left;
  font-feature-settings: "tnum";
  -moz-font-feature-settings: "tnum";
  -ms-font-feature-settings: "tnum";
  -webkit-font-feature-settings: "tnum";
}
tt,
code {
  background-color: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  font-family: "SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace;
  padding: 0;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}
pre code {
  background: none;
  line-height: 1.42;
}
code:before,
code:after,
tt:before,
tt:after {
  letter-spacing: -0.2em;
  content: " "
}
pre code:before,
pre code:after,
pre tt:before,
pre tt:after {
  content: ""
}

button {
  cursor: pointer;
  background-color: #9C2729;
  border: 1px solid #9C2729;
  color: #fff;
  display: block;
  font-size: 14px;
  line-height: 24px;
  margin: auto;
  max-width: 20rem;
  padding: 8px 1rem;
  text-decoration: none;
  text-align: center;
}

button:hover,
button:focus {
  background-color: transparent;
  color: #9C2729;
  transition: all 0.3s ease;
}

button[disabled],
button:disabled,
button[disabled]:hover, 
button:disabled:hover, 
button[disabled]:focus,
button:disabled:focus {
  background-color: #cacaca !important;
  border: 1px solid #cacaca !important;
  color: #333 !important;
  cursor: default;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}
/* All tables */
.cb-table {
  margin-bottom: 2rem;
  width: 100%;  
}

.cb-table .cell-active {
  color: #9C2729;
}

.cb-table th:after {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: relative;
  transform: rotate(135deg);
  width: 6px;
  margin-left: 5px;
  top: -2px;
}

.cb-table th.bottom:after {
  content: "";
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: relative;
  transform: rotate(-45deg);
  top: 0;
}

.cb-table th {
  position: relative;
  padding: 10px 5px;
  padding-right: 1rem;
}

.cb-table tbody tr {
  cursor: pointer;
  border-left: 5px solid transparent;
}

.cb-table-nocursor tbody tr {
  cursor: default;
}

.cb-table-alternative tbody tr:nth-child(even) {
  background-color: #EEF0F3;
}

.cb-table-hover tbody tr:hover,
.cb-table-hover tbody tr:nth-child(even):hover {
  background-color: rgba(156, 39, 41, 0.1);
  border-left: 5px solid rgba(156, 39, 41, 1);
}

.cb-table td {
  font-size: 14px;
  max-width: 10rem;
  min-height: 76px;
  padding: 15px 5px;
}

.cell-active {
  color: #9C2729;
}

@media only screen and (max-width: 990px) {
  .cb-table table {
    min-height: none; 
  }

  table {
    margin: 0;
    width: 100%;
  }

	/* Force table to not be like tables anymore */
	.cb-table table, 
	.cb-table thead, 
	.cb-table tbody, 
	.cb-table th, 
	.cb-table td, 
	.cb-table tr { 
		display: block; 
  }

  .cb-table tr {
    border-radius: 4px;
    border: solid 1px #f6f9fc;
    background-color: #ffffff;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);
    margin-bottom: 1rem;
  }

  .cb-table tr:nth-child(even) {
    background-color: #FFF;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
	.cb-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
  }
  
	.cb-table td { 
		/* Behave  like a "row" */
    border: none;
    max-width: 100%;
    min-height: 0;
    position: relative;
    padding: 10px;
		padding-left: 50%;
		white-space: normal;
		text-align:left;
  }
  
  .cb-table td.cb-pdf-file {
    padding-left: 10px;
  }
 
	.cb-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
    width: 45%; 
    opacity: 0.5;
    padding: 0 20px; 
		top: 10px;
		white-space: nowrap;
		text-align:left;
	}
 
	/*
	Label the data
	*/
  .cb-table td:before { content: attr(data-title); }
  
  .cb-table .cell-pdf-icon img {
    display: none;
  }
  .cb-table .cell-pdf-icon span {
    display: block;
    margin-left: 0;
  }
  
}

/* PDF Icon */
.cb-table .cell-pdf-icon span {
  display: block;
  margin-left: 2.5rem;
}

.cb-table .cell-pdf-icon img {
  margin-right: .5rem;
  position: absolute;
  top: 10px;
}

#convex-bond-matrix th {
  font-size: 12px;
  padding: 10px 10px 10px 5px;
  position: relative;
}

/* Latest updates tables */
.section-latest-transactions,
.section-latest-notices {
  padding-bottom: 2rem;
  width: 100%;
}

.cb-table-latest {
  margin-top: 1.5rem;
}

.cb-table-latest .cell-pdf-icon img {
  top: 16px;
}

.button-all.button-first {
  display: none;
}

.section-latest-transactions h2,
.section-latest-notices h2 {
  border-top: 1px solid hsla(0, 0%, 0%, 0.12);
  padding-top: 1rem;
}

.cb-button-pdf {
  min-width: 100%;
}

@media screen and (min-width: 990px) {
  .section-latest-transactions,
  .section-latest-notices {
    float: left;
    padding: 1rem;
    padding-bottom: 0;
  }
  .section-latest-transactions h2,
  .section-latest-notices h2 {
    border-top: none;
    padding-top: 0;
  }
  .section-latest-notices {
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.15);
  }
  .button-all {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }
  .button-all.button-first {
    float: right;
  }
  .cb-button-pdf {
    float: right;
    min-width: 100%;
  }
  .section-latest-transactions {
    padding: 2rem 5% 2rem 0;
    width: 60%;
  }
  .section-latest-notices {
    padding: 2rem 1rem;
    width: 40%;
  }
  .cb-table-latest td {
    height: 60px;
    padding: 2px 5px;
    position: relative;
  }
  .cb-table-narrow td {
    height: 40px;
    padding: 7px 5px;
  }
}
.react-tabs__tab {
  width: auto;
  display: inline-block;
  font-size: 16px;
}

ul.react-tabs__tab-list {
  width: 100%;
  display: block;
  white-space: nowrap;
  /* font-size: 0; */
  overflow: auto;
}

.react-tabs__tab--selected {
  background: #fff;
  color: black;
  border-radius: 0;
  border: none;
}
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #9C2729; /* Red */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
  margin: 10rem auto;
}

@media screen and (min-width: 768px) {
  .loader {
    margin: 15rem auto;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
