.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #9C2729; /* Red */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 0.8s linear infinite;
  margin: 10rem auto;
}

@media screen and (min-width: 768px) {
  .loader {
    margin: 15rem auto;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}